import { ActionContext } from '../../cra';
import MCNavigationComponent from '../../components/form/mc/mcNavigation';
import React from 'react';
class IndexPage extends React.Component {
  static contextType = ActionContext;
  componentDidMount() {
    this.context.trackCurrentPage('MS Page');
  }
  render() {
    return <MCNavigationComponent />;
  }
}

export default IndexPage;
